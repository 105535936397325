<template>
    <div>
        <div class="top-info">
            <span>日期
                {{pricedata.TradingDay}}
            </span>
            <span style="margin:0 20px">收盘价
                {{pricedata.close}}
            </span>
            <span style="margin-right:20px">涨跌
                <span :style="{'color':fontColorData[0].color}">{{this.fontcolor(pricedata.growth)}}</span>
            </span>
            <span>涨跌幅
                <span :style="{'color':fontColorData[1].color}">{{this.toPercent(this.fontcolor1(pricedata.growth_rate))}}</span>
            </span>
        </div>

        <div class="caption">{{data}}—— VaR和ES预测</div>
        <div style="height:474px;overflow:hidden">
            <div style="height:34px;">
                <div class="level_l">时间表：
                    <span @click="timeSelctHandle('1y')" style="padding:10px;" class="now current">1年</span>| 
                    <span @click="timeSelctHandle('3y')" style="padding:10px;" class="now">3年</span>|
                    <span @click="timeSelctHandle('5y')" style="padding:10px;" class="now">5年</span>|
                    <span @click="timeSelctHandle('10y')" style="padding:10px;" class="now">10年</span>
                </div>
                <div class="level_r">
                    <span>置信水平：99%</span>
                    <span style="margin:0 20px">预测区间：未来1日</span>
                    <span>更新频率：日频</span>
                </div>
            </div>
            <el-container style="height:440px;background:#E9EEF7;padding:0;margin:0;overflow:hidden">
                <el-main style="padding:0;margin:0;background:transparent">
                    <VarEsEcharts @callBackInfo="varhandleInfo" ref="varEsEcharts" style="padding:0" />
                </el-main>
                <el-aside width="190px" style="padding:0;height:450px;border-left:solid 1px #ccc">
                    <VarTable :partInfo="varinfo" :isHushenFont="this.$parent.$parent.$parent.isHushenFont" style="padding:0;margin:0"></VarTable>
                </el-aside>
            </el-container>       
        </div>
        <div class="summary">
            <div class="left">说明：</div>
            <div class="right" v-html="summaryVaR"></div>
        </div>

        <div class="caption">{{data}}—— 实际波动率走势</div>
        <div style="height:474px;">
            <div style="height:34px;">
                <div class="level_l" >时间表：
                    <span @click="dateSelctHandle('1y')" style="padding:10px; " class="now current">1年</span>| 
                    <span @click="dateSelctHandle('3y')" style="padding:10px;" class="now">3年</span>|
                    <span @click="dateSelctHandle('5y')" style="padding:10px;" class="now">5年</span>|
                    <span @click="dateSelctHandle('10y')" style="padding:10px;" class="now">10年</span>
                </div>
            </div>
            <el-container style="height:440px;background:#E9EEF7;padding:0;margin:0">
                <el-main style="padding:0;margin:0">
                    <RealizedVolEcharts @callBackInfo="eshandleInfo" ref="realizedVolEcharts" style="float:left" />
                </el-main>
                <el-aside width="190px" style="background:#E9EEF7;padding:0;border-left:solid 1px #ccc;height:100%">
                    <EsTable :partInfos="esinfo" :isHushenFont="this.$parent.$parent.$parent.isHushenFont" style="background:#E9EEF7;margin:0;padding:0"></EsTable>
                </el-aside>
            </el-container>
        </div>
        <div class="summary">
            <div class="left">说明：</div>
            <div class="right" v-html="summaryVol"></div>
        </div>

        <div class="caption">市场总结</div>
        <div style="text-align:left;color:#333;font-size:14px;padding:20px;">
            <!-- 文案 -->
            <div v-html="shichangzongjie"> {{shichangzongjie}} </div>
        </div>

        <!-- 免责声明 -->
        <div style="font-size:12px;margin:10px 0;text-indent:20px;border-top:solid 1px #ddd;padding-top:20px;">
            <div style="float:left;color:#666;">免责声明：</div>
           <div style="float:left;text-align:left;color:#999">
               <p>本图表（含文字）是基于金融市场行情信息和上市公司根据其法定义务公开披露的信息为核心依据的独立第三方研究。我司力求数据及分析客观公正，但不保证其准确性、完整性、及时性等；</p>
               <p>本图表（含文字）中的信息或所表述的意见不构成任何投资建议，我司不对因使用本图表所采取的任何行动承担任何责任。</p>
               <p>如对以上图表有疑问或建议，请联系<a style="color:#72A8E7" v-if="companyInfo">{{ companyInfo.email }}</a>。</p>
               <p>智君科技面向金融机构客户提供基于大数据和人工智能的定制化解决方案，服务涵盖数据产品的咨询、定制、实施与保障。</p>
               <p style="margin-bottom:20px">智君科技聚焦财富管理行业，通过打造专业、智能、合规、安全的产品服务赋能机构客户战略升级。</p>
           </div>
        </div>
    </div>
</template>

<script>
import VarEsEcharts from '@/components/tabComponents/VarEsEcharts'
import RealizedVolEcharts from '@/components/tabComponents/RealizedVolEcharts'
import VarTable from '@/components/vartable'
import EsTable from '@/components/estable'

export default {
    data () {
        return {
            tableData:[],
            TradingDay:'',
            close:'',
            growth:'',
            growth_rate:'',
            varinfo: {},
            esinfo: {},
            fontColorData: [
                {color:'#E64545',name:'红色'},
                {color:'#67B35A',name:'绿色'}
            ],
            method: 'garch_skewt',
            words_garch_skewt_0: '上图中某日期的VaR（ES）预测值是指根据截止至前一交易日的数据，对该日期的VaR（ES）的估计。右侧表格中的“下一个交易日预测”为截止日期对下一交易日的VaR（ES）的估计。“变化”指VaR（ES）预测值减去前值。<br>风险预测模型采用GARCH模型结合偏态T分布的噪声假设，更精确地阐释指数收益序列的厚尾、偏移和波动集聚的动态特征。',
            words_garch_skewt_1: '上图中某日期的实际波动率指该日期的指数收益波动。右侧表格中“波动率”为在截止日期当天的指数收益波动。“变化”指波动率现值减去前值。<br>动态波动率计算基于GARCH模型，滚动波动率计算采用过去60日滚动窗口的样本标准差。',
            words_ewma_hs_0: '上图中某日期的VaR（ES）预测值是指根据截止至前一交易日的数据，对该日期的VaR（ES）的估计。右侧表格中的“下一个交易日预测”为截止日期对下一交易日的VaR（ES）的估计。“变化”指VaR（ES）预测值减去前值。<br>风险预测模型采用EWMA模型，给予近期观测值更高的权重。',
            words_ewma_hs_1: '上图中某日期的实际波动率指该日期的指数收益波动。右侧表格中“波动率”为在截止日期当天的指数收益波动。“变化”指波动率现值减去前值。<br>动态波动率计算基于EWMA模型，滚动波动率计算采用过去60日滚动窗口的样本标准差。'
        };
    },
    props:['data'],
    components:{
        VarEsEcharts,
        RealizedVolEcharts,
        VarTable,
        EsTable
    },
    computed: {
        companyInfo: function() {
            return this.$store.state.zongjie.companyInfo
        },
        pricedata: function() {
            return this.$store.state.price.pricedata;
        },   
        shichangzongjie: function() {
            return this.$store.state.zongjie.content;
        }, 
        summaryVaR() {
            return this[`words_${this.method}_0`]
        },
        summaryVol() {
            return this[`words_${this.method}_1`]
        }
    },          
    mounted(){
        this.$nextTick(() => {
            this.initEcharts()
            this.$store.dispatch('price/getLatestIndexPriceAndReturn',this.$parent.$parent.$parent.index_code)
            this.$store.dispatch('zongjie/getModelContentByType',this.$parent.$parent.$parent.$options.name)
        })
    },        
    methods: { 
        changeData:function(){
            let origin = this.$parent.$parent.$parent
            let params = {
                "index_code": origin.index_code,
                "length": origin.length,
                "method": origin.method,
                "down_color": origin.down_color,
                "up_color": origin.up_color
            };     
            this.$refs.varEsEcharts.changeData(params) ;
            this.$refs.realizedVolEcharts.changeData(params) ;
            // 切换文案
            this.method = origin.method
        },
        initEcharts: function () {
            //调用子组件的函数，并把这个状态传过去。
            let params = {
                "index_code": this.$parent.$parent.$parent.index_code,
                "length": '1y',
                "method": this.$parent.$parent.$parent.method,
                "down_color": this.$parent.$parent.$parent.down_color,
                "up_color": this.$parent.$parent.$parent.up_color
            };     
            this.$refs.varEsEcharts.changeData(params) ;
            this.$refs.realizedVolEcharts.changeData(params) ;
        },             
        timeSelctHandle:function(value){
            let params = {
                "index_code": this.$parent.$parent.$parent.index_code,
                "length": value,
                "method": this.$parent.$parent.$parent.method,
                "down_color": this.$parent.$parent.$parent.down_color,
                "up_color": this.$parent.$parent.$parent.up_color
            };
            this.$refs.varEsEcharts.changeData(params) ;
            if(value=='1y'){
                document.getElementsByClassName('now')[0].classList.add("current")
                document.getElementsByClassName('now')[1].classList.remove("current")
                document.getElementsByClassName('now')[2].classList.remove("current")
                document.getElementsByClassName('now')[3].classList.remove("current")
            }else if(value=='3y'){
                document.getElementsByClassName('now')[1].classList.add("current")
                document.getElementsByClassName('now')[0].classList.remove("current")
                document.getElementsByClassName('now')[2].classList.remove("current")
                document.getElementsByClassName('now')[3].classList.remove("current")
            }else if(value=='5y'){
                document.getElementsByClassName('now')[2].classList.add("current")
                document.getElementsByClassName('now')[0].classList.remove("current")
                document.getElementsByClassName('now')[1].classList.remove("current")
                document.getElementsByClassName('now')[3].classList.remove("current")
            }else if(value=='10y'){
                document.getElementsByClassName('now')[3].classList.add("current")
                document.getElementsByClassName('now')[0].classList.remove("current")
                document.getElementsByClassName('now')[1].classList.remove("current")
                document.getElementsByClassName('now')[2].classList.remove("current")
            }
        },
        dateSelctHandle:function(value){
            let params = {
                "index_code": this.$parent.$parent.$parent.index_code,
                "length": value,
                "method": this.$parent.$parent.$parent.method,
                "down_color": this.$parent.$parent.$parent.down_color,
                "up_color": this.$parent.$parent.$parent.up_color
            };
            this.$refs.realizedVolEcharts.changeData(params) ;
            if(value=='1y'){
                document.getElementsByClassName('now')[4].classList.add("current")
                document.getElementsByClassName('now')[5].classList.remove("current")
                document.getElementsByClassName('now')[6].classList.remove("current")
                document.getElementsByClassName('now')[7].classList.remove("current")
            }else if(value=='3y'){
                document.getElementsByClassName('now')[5].classList.add("current")
                document.getElementsByClassName('now')[4].classList.remove("current")
                document.getElementsByClassName('now')[6].classList.remove("current")
                document.getElementsByClassName('now')[7].classList.remove("current")
            }else if(value=='5y'){
                document.getElementsByClassName('now')[6].classList.add("current")
                document.getElementsByClassName('now')[4].classList.remove("current")
                document.getElementsByClassName('now')[5].classList.remove("current")
                document.getElementsByClassName('now')[7].classList.remove("current")
            }else if(value=='10y'){
                document.getElementsByClassName('now')[7].classList.add("current")
                document.getElementsByClassName('now')[4].classList.remove("current")
                document.getElementsByClassName('now')[5].classList.remove("current")
                document.getElementsByClassName('now')[6].classList.remove("current")
            }
        },
        varhandleInfo(data){
            this.varinfo = data
        },
        eshandleInfo(data){
            this.esinfo = data
        },
        //小数变成百分数并保留两位小数
        toPercent(point){
            var str=Number(point).toFixed(2);
            str+="%";
            return str;
        },
        fontcolor(data){
            //沪深指数 正是红色，负数是绿色，其余指数相反
            let isHushenFont = this.$parent.$parent.$parent.isHushenFont;
            if(data>0){
                if(isHushenFont){
                    this.fontColorData[0].color='#E64545';
                }else{
                    this.fontColorData[0].color='#67B35A';
                }
                let str="+"
                return str+data
            }else{
                if(isHushenFont){
                    this.fontColorData[0].color='#67B35A';
                }else{
                    this.fontColorData[0].color='#E64545';
                }
                return data
            }
        },
        fontcolor1(data){
            let isHushenFont = this.$parent.$parent.$parent.isHushenFont;
            if(data<0){
                if(isHushenFont){
                    this.fontColorData[1].color='#67B35A';
                }else{
                    this.fontColorData[1].color='#E64545';
                }
                return data 
            }else{
                if(isHushenFont){
                    this.fontColorData[1].color='#E64545';
                }else{
                    this.fontColorData[1].color='#67B35A';
                }
                return data
            }
        }
    }
}
</script>

<style lang='less' scoped>
.top-info{
    height:40px;
    line-height:40px;
    text-align:left;
    margin-left:16px;
    font-size:14px;
    color:#666;
}
.caption{
    width:101%;
    background:#FEFBEF;
    height:60px;
    text-indent:16px;
    // padding:0 50px;
    line-height:60px;
    font-size:20px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(0,0,0,1);
    text-align: left;
}
.level_l{
    width:45%;
    height:34px;
    line-height:34px;
    // background:pink;
    float:left;
    text-align:left;
    margin-left:16px;
    font-size:12px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);
    span{
        cursor: pointer;
        // &:hover{
        //     // color:#5F80A9;
        //     font-weight:bold;
        //     // font-size:16px;
        // }
    }
}
.level_r{
    width:45%;
    height:34px;
    line-height:34px;
    // background:pink;
    float:right;
    text-align:right;
    font-size:12px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(153,153,153,1);
    margin-right:20px;
}

.transaction,.exceed{
    height:65px;
    margin-top:20px;
    font-size:14px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(85,85,85,1);
    border-bottom:solid 1px #ccc;
    // background:red;
}
.var,.change1,.es,.change2,.tradeday,.shouyi{
    height:35px;
    line-height:35px;
    border-bottom: solid 1px #ccc;
    font-size:14px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(102,102,102,1);

}
.shouyi{
    border:0;

}

.el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 0px !important;
    display:none;
}
.el-table__body tr,
  .el-table__body td {
    padding: 0;
    height: 0px !important;
    display:none;
}
.el-table th {
    user-select: none;
    display: none;
}
.el-table tr td, .el-table th {
    padding: 12px 0;
    min-width: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: left;
    display: none;
}
.current{
    color:#333;
    font-weight:bold;
}

.summary{
    padding: 20px 24px;
    text-align: left;
    color: rgb(153, 153, 153);
    font-size: 12px;
    overflow: hidden;
    .left, .right{
        float: left;
    }
    .left{
        width: 44px;
        color: rgb(102, 102, 102);
    }
    .right{
        width: calc(100% - 44px);
    }
}
</style>